import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormatService {
  constructor() {}

  /**
   * This function removes all keys with `undefined` or `null` values from an object,
   * including nested objects, while preserving other types and structure.
   */
  cleanObject<T>(obj: T): T {
    if (obj === null || obj === undefined) {
      return obj; // Return the object as is if it is null or undefined
    }

    if (typeof obj !== 'object' || Array.isArray(obj)) {
      return obj; // Return the object as is if it is not an object or if it is an array
    }

    const cleanedObject = {} as T;

    for (const [key, value] of Object.entries(obj)) {
      if (value !== null && value !== undefined) {
        // If the value is an object, recursively clean it
        cleanedObject[key as keyof T] = typeof value === 'object' && !Array.isArray(value) ? this.cleanObject(value) : value;
      }
    }

    return cleanedObject;
  }

  deleteEmptyValues(obj: Record<string, any>) {
    Object.keys(obj).forEach((key) => {
      if (obj[key] === null || (typeof obj[key] === 'string' && obj[key].trim() === '')) {
        delete obj[key];
      } else if (typeof obj[key] === 'object') {
        this.deleteEmptyValues(obj[key]);
        if (Object.keys(obj[key]).length === 0) {
          delete obj[key];
        }
      }
    });

    return obj;
  }
}
